import React from "react";
import CardPage from "~/components/CardPage";
import SignUpEmailForm from "~/features/marketing/SignUpEmailForm";
import authBackgroundImage from "~/images/auth-background.jpg";
import "~/features/marketing/sign-up.css";

function GeneralSignUpPage() {
  return (
    <CardPage backgroundImage={authBackgroundImage}>
      <div className="sign-up-form">
        <h2 className="text-center mb-2">Sign Up for Families</h2>
        <p className="sign-up-warning">
          ProblemScape is currently available for download on iPads, Android
          tablets, Chromebooks, and PCs (macOS). Please note
          {/* tablets, Chromebooks, and PCs (both macOS and Windows). Please note */}
          that ProblemScape is not compatible with phones.
        </p>
        <SignUpEmailForm type="parent" className="sign-up-email-form" />
      </div>
    </CardPage>
  );
}

export default GeneralSignUpPage;
